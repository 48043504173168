<template>
	<v-app class="login no-auth">
		<div
			class="tw-h-screen tw-overflow-x-hidden tw-overflow-y-auto tw-flex tw-flex-col"
		>
			<div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-flex-1">
				<div
					class="tw-place-content-center tw-px-4 md:tw-px-0 tw-hidden xl:tw-grid"
				>
					<div class="tw-text-center tw-max-w-md">
						<img
							class="tw-h-52 tw-object-contain tw-mx-auto"
							src="@/assets/images/logo.png"
							alt
							srcset
						/>
						<h3
							class="tw-font-semibold tw-text-2xl md:tw-text-3xl 3xl:tw-text-4xl tw-mt-6 md:tw-mt-10 3xl:tw-mt-14"
						>
							{{ $t('login.welcomeToMachla') }}
						</h3>
						<p class="3xl:tw-text-xl tw-mt-3 3xl:tw-mt-5 tw-text-70">
							{{ $t('login.buyingAndSellingMakeEasy') }}
						</p>
						<div class="tw-mt-20 xl:tw-mt-72">
							<!-- <v-btn dark color="#A53E50" block>Login</v-btn> -->
							<!-- class="tw-mt-4 md:tw-mt-7" -->
							<v-btn
								:height="$vuetify.breakpoint.mdAndUp ? 60 : null"
								to="/login"
								outlined
								color="#EEEEEE"
								block
								class="tw-flex tw-justify-between tw-px-4 xl:tw-px-8"
							>
								<v-icon color="black">mdi-exit-to-app</v-icon>
								<span
									class="tw-text-black tw-font-pop tw-normal-case tw-text-base xl:tw-text-lg tw-font-normal tw-text-center tw-flex-1"
									>{{ $t('login.login') }}</span
								>
							</v-btn>
						</div>
					</div>
				</div>
				<div class="tw-bg-72 tw-grid tw-place-content-center">
					<div
						v-if="!onSuccessRegistration"
						class="tw-w-full lg:tw-max-w-3xl tw-px-5 md:tw-px-0 tw-mx-4 tw-overflow-y-auto tw-my-8"
					>
						<div class="tw-px-4">
							<h2 class="tw-font-semibold tw-text-27px tw-text-FF">
								{{ $t(`${as}Text`) }} {{ $t('signUpText') }}
							</h2>
							<p class="tw-text-17px tw-text-FF tw-mt-2 tw-opacity-70">
								{{ $t('signUp.buyerSignUpHint') }}
							</p>
						</div>

						<v-alert
							dense
							outlined
							dismissible
							v-model="error.dialog"
							type="error"
							>{{ error.message }}</v-alert
						>
						<buyerSignUpForm
							:role="as"
							:loading="loading"
							:onSubmit="handleRegistration"
						/>
						<!-- @registration="handleRegistration" -->
					</div>
					<div v-else class="tw-text-center tw-w-full sm:tw-w-96">
						<img
							class="tw-w-60 md:tw-w-420 tw-object-cover"
							src="@/assets/images/success.png"
							alt
							srcset
						/>

						<h2
							class="tw-text-27px tw-font-semibold tw-text-white tw-mb-4 tw-mt-8"
						>
							{{ $t('signUp.registrationCompleted') }}
						</h2>
						<span class="tw-text-white tw-opacity-70">{{
							$t('signUp.registrationCompletedHint')
						}}</span>
						<div class="tw-mt-16 xl:tw-mt-24">
							<!-- <v-btn dark color="#A53E50" block>Login</v-btn> -->
							<!-- class="tw-mt-4 md:tw-mt-7" -->
							<v-btn
								height="60"
								depressed
								color="rgba(242,202,81,0.2)"
								block
							>
								<span
									class="tw-text-F2 tw-text-18 tw-font-pop tw-normal-case tw-text-base xl:tw-text-lg tw-font-normal tw-text-center tw-flex-1"
									>{{ $t('signUp.pendingApproval') }}</span
								>
							</v-btn>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="overflow-visible login__card ">
      <v-card dark :loading="loading" :disabled="loading" class="glass blur-1">
        <div class="px-3 py-12 px-md-8">
          <v-card-title class="title login__title flex-column"
            >Welcome back!</v-card-title
          >
          <v-card-subtitle class="text-center baody-2"
            >Login with your Email and password</v-card-subtitle
          >
          <v-card-text class="mt-5">
            <v-alert
              dense
              outlined
              dismissible
              v-model="error.dialog"
              type="error"
            >
              {{ error.message }}
            </v-alert>
          </v-card-text>
        </div>
      </v-card>
		</div>-->
	</v-app>
</template>

<script>
import { mapActions } from 'vuex'
import { queryString, changeLocationQuery } from 'vuelpers'

import buyerSignUpForm from '@/components/forms/buyerSignUpForm'

export default {
	name: 'SignUp',
	// metaInfo: {
	// 	title: "SignUp | Machla",
	// 	meta: [
	// 		{
	// 			name: "description",
	// 			content: "SignUp"
	// 		}
	// 	]
	// },
	components: {
		buyerSignUpForm,
	},
	data() {
		return {
			loading: false,
			onSuccessRegistration: false,
			error: {
				dialog: false,
				message: '',
			},
		}
	},
	created() {
		if (this.as !== this.$route.query.as) {
			changeLocationQuery(
				queryString.stringify({
					as: this.as,
				})
			)
		}
	},
	computed: {
		as() {
			let as = this.$route.query.as
			return ['buyer', 'supplier'].includes(as) ? as : 'buyer'
		},
	},
	methods: {
		...mapActions('auth', ['buyerRegistration', 'supplierRegistration']),
		async handleRegistration(data) {
			this.loading = true
			const func =
				this.as === 'buyer'
					? this.buyerRegistration
					: this.supplierRegistration
			const [err, res] = await func(data)
			this.loading = false

			if (!err) this.onSuccessRegistration = true

			return [err, res]

			// this.$toast.success("Successfully logged in.");
			// let { redirect } = { ...this.$route.query };
			// this.$router.replace(redirect ? redirect : "/");
		},
	},
}
</script>

<style lang="scss" type="text/scss" scoped>
.login {
	&__card {
		width: 450px;
		max-width: 90%;
		@include center;
	}
}
</style>
