<template>
	<v-stepper
		v-model="currentStep"
		flat
		class="base-stepper"
		style="background: #722432"
	>
		<v-stepper-header flat>
			<v-stepper-step
				:complete="currentStep > 1"
				step="1"
				editable
				color="#F2CA51"
			>
				<div
					class="tw-absolute tw-h-8 tw-w-8 tw-grid tw-place-items-center tw-left-6 tw-top-6 tw-rounded-full icon-check"
				>
					<icon-check />
				</div>
				<span class="tw-text-white">{{ $t('signUp.companyInfo') }}</span>
			</v-stepper-step>

			<v-divider></v-divider>

			<v-stepper-step
				color="#F2CA51"
				:editable="2 < currentStep"
				:complete="currentStep > 2"
				step="2"
			>
				<div
					class="tw-absolute tw-h-8 tw-w-8 tw-grid tw-place-items-center tw-left-6 tw-top-6 tw-rounded-full icon-check"
				>
					<icon-check />
				</div>
				<span class="tw-text-white">{{ $t('signUp.address') }}</span>
			</v-stepper-step>

			<v-divider></v-divider>

			<v-stepper-step color="#F2CA51" step="3">
				<div
					class="tw-absolute tw-h-8 tw-w-8 tw-grid tw-place-items-center tw-left-6 tw-top-6 tw-rounded-full icon-check"
				>
					<icon-check />
				</div>
				<span class="tw-text-white">{{
					$t('signUp.bankInformation')
				}}</span>
			</v-stepper-step>
		</v-stepper-header>

		<v-stepper-items class="pt-0 mt-3">
			<v-stepper-content class="pt-0 mt-0" step="1">
				<v-form ref="companyForm" @submit.prevent="validateCompany">
					<v-row>
						<v-col cols="12">
							<div>
								<div
									class="tw-flex tw-items-center tw-space-s-3.5 tw-text-white tw-mb-4"
								>
									<svg
										id="building-4-svgrepo-com"
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
									>
										<path
											id="Path_34279"
											data-name="Path 34279"
											d="M0,0H24V24H0Z"
											fill="none"
										/>
										<path
											id="Path_34280"
											data-name="Path 34280"
											d="M21,20h2v2H1V20H3V3A1,1,0,0,1,4,2H20a1,1,0,0,1,1,1Zm-2,0V4H5V20ZM8,11h3v2H8ZM8,7h3V9H8Zm0,8h3v2H8Zm5,0h3v2H13Zm0-4h3v2H13Zm0-4h3V9H13Z"
											fill="#fff"
										/>
										<rect
											id="Rectangle_17559"
											data-name="Rectangle 17559"
											width="3"
											height="2"
											transform="translate(8 7)"
											fill="#c9acb1"
										/>
										<rect
											id="Rectangle_17562"
											data-name="Rectangle 17562"
											width="3"
											height="2"
											transform="translate(8 11)"
											fill="#c9acb1"
										/>
										<rect
											id="Rectangle_17564"
											data-name="Rectangle 17564"
											width="3"
											height="2"
											transform="translate(8 15)"
											fill="#c9acb1"
										/>
										<rect
											id="Rectangle_17560"
											data-name="Rectangle 17560"
											width="3"
											height="2"
											transform="translate(13 7)"
											fill="#c9acb1"
										/>
										<rect
											id="Rectangle_17561"
											data-name="Rectangle 17561"
											width="3"
											height="2"
											transform="translate(13 11)"
											fill="#c9acb1"
										/>
										<rect
											id="Rectangle_17563"
											data-name="Rectangle 17563"
											width="3"
											height="2"
											transform="translate(13 15)"
											fill="#c9acb1"
										/>
									</svg>

									<span class="tw-font-medium">{{
										$t('signUp.companyName')
									}}</span>
								</div>
								<v-text-field
									solo
									hide-details="auto"
									class="focus-bg-none"
									:placeholder="$t('signUp.companyNamePlaceholder')"
									:rules="[rules.required($t('signUp.companyName'))]"
									v-model="registration.company.name"
								/>
							</div>
						</v-col>

						<v-col cols="12" md="6">
							<div>
								<div
									class="tw-flex tw-items-center tw-space-s-3.5 tw-text-white tw-mb-4"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="17.689"
										height="20.722"
										viewBox="0 0 17.689 20.722"
									>
										<g
											id="copy_11_"
											data-name="copy (11)"
											transform="translate(-4.681 -2.895)"
										>
											<path
												id="Path_31205"
												data-name="Path 31205"
												d="M20.661,24.222H8.528A2.531,2.531,0,0,1,6,21.695V6.528A2.531,2.531,0,0,1,8.528,4H20.661a2.531,2.531,0,0,1,2.528,2.528V21.695A2.531,2.531,0,0,1,20.661,24.222ZM8.528,5.011A1.519,1.519,0,0,0,7.011,6.528V21.695a1.519,1.519,0,0,0,1.517,1.517H20.661a1.519,1.519,0,0,0,1.517-1.517V6.528a1.519,1.519,0,0,0-1.517-1.517Z"
												transform="translate(-1.069 -0.855)"
												fill="#fff"
												stroke="#fff"
												stroke-width="0.5"
											/>
											<path
												id="Path_31207"
												data-name="Path 31207"
												d="M18.595,17.011H10.506a.506.506,0,1,1,0-1.011h8.089a.506.506,0,0,1,0,1.011Z"
												transform="translate(-1.024 0.755)"
												fill="#c9acb1"
											/>
											<path
												id="Path_31209"
												data-name="Path 31209"
												d="M18.595,13.011H10.506a.506.506,0,0,1,0-1.011h8.089a.506.506,0,0,1,0,1.011Z"
												transform="translate(-1.024 0.711)"
												fill="#c9acb1"
											/>
											<path
												id="Path_31210"
												data-name="Path 31210"
												d="M18.595,9.011H10.506a.506.506,0,0,1,0-1.011h8.089a.506.506,0,0,1,0,1.011Z"
												transform="translate(-1.024 0.666)"
												fill="#c9acb1"
											/>
										</g>
									</svg>

									<span class="tw-font-medium">
										{{ $t('signUp.commercialRegistrationNo') }}</span
									>
								</div>
								<v-text-field
									solo
									:rules="[rules.required()]"
									v-model="registration.company.registrationNumber"
									class="focus-bg-none"
									:placeholder="
										$t('signUp.commercialRegistrationNoPlaceholder')
									"
									hide-details="auto"
								/>
							</div>
						</v-col>
						<v-col cols="12" md="6">
							<div>
								<div
									class="tw-flex tw-items-center tw-space-s-3.5 tw-text-white tw-mb-4"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="18.012"
										height="21.134"
										viewBox="0 0 18.012 21.134"
									>
										<g
											id="date-svgrepo-com_1_"
											data-name="date-svgrepo-com (1)"
											transform="translate(-227.954 -239.458)"
										>
											<g id="_x35_4_37_">
												<g id="Group_40292" data-name="Group 40292">
													<path
														id="Path_34274"
														data-name="Path 34274"
														d="M51.362,1.711H50.3V.906A.906.906,0,0,0,49.389,0H49.06a.906.906,0,0,0-.906.906v.805H41.816V.906A.906.906,0,0,0,40.91,0H40.58a.906.906,0,0,0-.906.906v.805H38.242A2.449,2.449,0,0,0,35.8,4.158v14.53a2.449,2.449,0,0,0,2.446,2.446h13.12a2.449,2.449,0,0,0,2.446-2.446V4.158A2.449,2.449,0,0,0,51.362,1.711Zm.973,16.976a.974.974,0,0,1-.973.973H38.242a.974.974,0,0,1-.973-.973V4.158a.974.974,0,0,1,.973-.973h1.432V4.342a.906.906,0,0,0,.906.906h.329a.906.906,0,0,0,.906-.906V3.185h6.338V4.342a.906.906,0,0,0,.906.906h.329a.906.906,0,0,0,.906-.906V3.185h1.067a.974.974,0,0,1,.973.973v14.53Z"
														transform="translate(192.158 239.458)"
														fill="#fff"
													/>
												</g>
											</g>
										</g>
									</svg>
									<span class="tw-font-medium">{{
										$t('signUp.recordExpirationDate')
									}}</span>
								</div>
								<!-- <v-text-field
										solo
										:rules="[rules.required('Record Expiration Date')]"
										v-model="registration.company.recordExpDate"
										class="focus-bg-none"
										placeholder="Expiration Date"
										hide-details="auto"
									/>-->
								<v-menu
									ref="menu"
									v-model="menu"
									:close-on-content-click="false"
									:return-value.sync="
										registration.company.recordExpDate
									"
									transition="scale-transition"
									offset-y
									min-width="auto"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											solo
											:rules="[rules.required($t('date'))]"
											v-model="registration.company.recordExpDate"
											class="focus-bg-none"
											append-icon="mdi-calendar"
											:placeholder="
												$t('signUp.recordExpirationDatePlaceholder')
											"
											hide-details="auto"
											v-bind="attrs"
											v-on="on"
										/>
									</template>
									<v-date-picker
										no-title
										scrollable
										v-model="registration.company.recordExpDate"
										:min="
											$m()
												.add(1, 'day')
												.format('YYYY-MM-DD')
										"
									>
										<v-spacer></v-spacer>
										<v-btn
											text
											color="primary"
											@click="menu = false"
											>{{ $t('cancel-label') }}</v-btn
										>
										<v-btn
											text
											color="primary"
											@click="
												$refs.menu.save(
													registration.company.recordExpDate
												)
											"
											>{{ $t('ok') }}</v-btn
										>
									</v-date-picker>
								</v-menu>
							</div>
						</v-col>
						<v-col>
							<div class="v-input">
								<div>
									<div
										class="tw-mt-2.5 tw-border-dashed tw-border-2 tw-border-DF tw-border-opacity-70 tw-rounded-lg tw-ps-6 tw-pe-2 tw-py-2 tw-flex tw-flex-col md:tw-flex-row md:tw-space-s-4 tw-items-center tw-justify-between tw-space-y-2 md:tw-space-y-0"
									>
										<div class="tw-flex tw-flex-col tw-space-y-1.5">
											<span class="tw-font-medium tw-text-white">{{
												$t('signUp.companyAttachment')
											}}</span>
											<span
												class="tw-font-medium tw-text-CA tw-text-xs"
												>{{
													$t('signUp.companyAttachmentDescreption')
												}}</span
											>
										</div>
										<v-chip
											class="tw-px-4"
											v-if="
												this.registration.company
													.registrationAttachment
											"
											dark
											color="primary"
										>
											<span class="tw-w-20 tw-truncate">{{
												this.registration.company
													.registrationAttachment.name
											}}</span>
											<v-icon class="tw-me-2">mdi-file</v-icon>
										</v-chip>
										<v-btn
											@click="onCompanyAttachment"
											width="152"
											height="52"
											class="tw-rounded-md"
											depressed
											dark
											color="#F2CA51"
										>
											<icon-login class="tw-me-3 xl:tw-me-6" />
											<span
												class="tw-text-base xl:tw-text-lg tw-normal-case tw-font-normal"
												>{{ $t('actions.upload') }}</span
											>
										</v-btn>
									</div>
									<span
										v-if="showCompanyAttachError"
										class="error--text tw-ms-2 tw-text-xs"
									>
										{{ $t('comRegAttReq') }}
									</span>
								</div>
							</div>
						</v-col>
						<v-col cols="12">
							<div
								class="tw-flex tw-items-center tw-space-s-3.5 tw-text-white tw-mb-4 tw-mt-1"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="22"
									height="22"
									viewBox="0 0 22 22"
								>
									<g
										id="store-svgrepo-com_2_"
										data-name="store-svgrepo-com (2)"
										transform="translate(0 0.122)"
									>
										<path
											id="Path_34275"
											data-name="Path 34275"
											d="M21.119,8.562l-1.366-4.1A.683.683,0,0,0,19.07,4H4.045a.683.683,0,0,0-.683.464L2,8.562A.813.813,0,0,0,2,8.78v4.1a.683.683,0,0,0,.683.683h.683V20.39H4.728V13.561h4.1V20.39H19.753V13.561h.683a.683.683,0,0,0,.683-.683V8.78a.813.813,0,0,0,0-.219ZM18.387,19.024h-8.2V13.561h8.2Zm1.366-6.829H17.021V9.463H15.656v2.732H12.241V9.463H10.875v2.732H7.46V9.463H6.094v2.732H3.362V8.889L4.537,5.365H18.579l1.175,3.524Z"
											transform="translate(-0.631 -1.244)"
											fill="#fff"
										/>
										<rect
											id="_Transparent_Rectangle_"
											data-name="&lt;Transparent Rectangle&gt;"
											width="22"
											height="22"
											transform="translate(0 -0.122)"
											fill="none"
										/>
									</g>
								</svg>

								<span class="tw-font-medium">{{
									$t('signUp.storeName')
								}}</span>
							</div>
							<v-text-field
								solo
								v-model="registration.company.storeName"
								class="focus-bg-none"
								:placeholder="$t('signUp.storeNamePlaceholder')"
								hide-details="auto"
							/>
						</v-col>
						<v-col cols="12" md="6">
							<div>
								<div
									class="tw-flex tw-items-center tw-space-s-3.5 tw-text-white tw-mb-4"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="17.815"
										height="17.815"
										viewBox="0 0 17.815 17.815"
									>
										<path
											id="phone-svgrepo-com"
											d="M19.313,15.214V17.67a1.664,1.664,0,0,1-1.788,1.637A14.345,14.345,0,0,1,3.006,4.785,1.663,1.663,0,0,1,4.639,3H7.1a1.642,1.642,0,0,1,1.086.4c.858.737,1.41,3.236,1.2,4.23C9.217,8.4,8.435,8.945,7.9,9.475a13.111,13.111,0,0,0,4.921,4.912c.532-.531,1.076-1.311,1.853-1.477,1-.212,3.513.34,4.247,1.2A1.635,1.635,0,0,1,19.313,15.214Z"
											transform="translate(-2.249 -2.25)"
											fill="none"
											stroke="#fff"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="1.5"
										/>
									</svg>

									<span class="tw-font-medium"
										>{{ $t('signUp.phoneNumber') }}
									</span>
								</div>
								<v-text-field
									v-model="registration.company.phone"
									:placeholder="$t('signUp.phoneNumberPlaceholder')"
									:rules="[
										rules.required($t('signUp.phoneNumber')),
										rules.phoneCountry(parsed => {
											registration.company.phone = parsed.number
										}),
									]"
									solo
									type="tel"
									hide-details="auto"
									class="focus-bg-none"
									:error-messages="errors.companyPhone"
									@input="onInputCompanyPhone"
								/>
							</div>
						</v-col>
						<v-col cols="12" md="6">
							<div>
								<div
									class="tw-flex tw-items-center tw-space-s-3.5 tw-text-white tw-mb-4"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="19.113"
										height="14.36"
										viewBox="0 0 19.113 14.36"
									>
										<path
											id="envelope_2_"
											data-name="envelope (2)"
											d="M1.782,59.882H17.23a1.784,1.784,0,0,1,1.782,1.782V72.359a1.784,1.784,0,0,1-1.782,1.782H1.782A1.784,1.784,0,0,1,0,72.359V61.664A1.784,1.784,0,0,1,1.782,59.882Zm0,1.188a.591.591,0,0,0-.228.046l7.952,6.892,7.952-6.892a.591.591,0,0,0-.228-.046Zm0,11.883H17.23a.6.6,0,0,0,.594-.594V62.372L9.9,69.243a.6.6,0,0,1-.779,0L1.188,62.372v9.987A.6.6,0,0,0,1.782,72.953Z"
											transform="translate(0.05 -59.832)"
											fill="#fff"
											stroke="#fff"
											stroke-width="0.1"
										/>
									</svg>

									<span class="tw-font-medium">{{
										$t('signUp.companyEmail')
									}}</span>
								</div>
								<v-text-field
									v-model="registration.company.email"
									:placeholder="$t('signUp.companyEmailPlaceholder')"
									:rules="[
										rules.required($t('signUp.companyEmail')),
										rules.email,
									]"
									solo
									type="email"
									class="focus-bg-none"
									hide-details="auto"
									:error-messages="errors.companyEmail"
									@input="errors.companyEmail = null"
								/>
							</div>
						</v-col>
						<v-col cols="12" md="6">
							<div>
								<div
									class="tw-flex tw-items-center tw-space-s-3.5 tw-text-white tw-mb-4"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20.145"
										height="17.076"
										viewBox="0 0 20.145 17.076"
									>
										<g
											id="case-svgrepo-com"
											transform="translate(0.25 -39.75)"
										>
											<path
												id="Path_34276"
												data-name="Path 34276"
												d="M19.031,43.07h-4.3a.614.614,0,0,0-.614-.614v-.614A1.844,1.844,0,0,0,12.278,40H7.367a1.844,1.844,0,0,0-1.842,1.842v.614a.614.614,0,0,0-.614.614H.614A.614.614,0,0,0,0,43.683V55.348a.614.614,0,0,0,.614.614H2.456a.614.614,0,0,0,.614.614H4.3a.614.614,0,0,0,.614-.614h9.823a.614.614,0,0,0,.614.614h1.228a.614.614,0,0,0,.614-.614h1.842a.614.614,0,0,0,.614-.614V43.683A.614.614,0,0,0,19.031,43.07ZM6.753,41.842a.615.615,0,0,1,.614-.614h4.911a.615.615,0,0,1,.614.614v.614a.614.614,0,0,0-.614.614H7.367a.614.614,0,0,0-.614-.614ZM18.417,54.734H1.228V44.3h17.19Z"
												fill="#fff"
												stroke="#fff"
												stroke-width="0.5"
											/>
											<path
												id="Path_34277"
												data-name="Path 34277"
												d="M112.614,206.753a.614.614,0,0,0,.614-.614v-5.525a.614.614,0,1,0-1.228,0v5.525A.614.614,0,0,0,112.614,206.753Z"
												transform="translate(-107.703 -153.861)"
												fill="#c9acb1"
											/>
											<path
												id="Path_34278"
												data-name="Path 34278"
												d="M368.614,206.753a.614.614,0,0,0,.614-.614v-5.525a.614.614,0,1,0-1.228,0v5.525A.614.614,0,0,0,368.614,206.753Z"
												transform="translate(-353.88 -153.861)"
												fill="#c9acb1"
											/>
										</g>
									</svg>

									<span class="tw-font-medium">{{
										$t('signUp.mainActivity')
									}}</span>
								</div>
								<v-select
									hide-details="auto"
									:rules="[rules.required($t('signUp.mainActivity'))]"
									class="focus-bg-none"
									:placeholder="$t('signUp.mainActivityPlaceholder')"
									:items="activity"
									item-text="label"
									item-value="value"
									v-model="registration.company.mainActivity"
									:label="$t('signUp.mainActivity')"
									solo
								></v-select>
							</div>
						</v-col>
						<v-col cols="12" md="6">
							<div>
								<div
									class="tw-flex tw-items-center tw-space-s-3.5 tw-text-white tw-mb-4"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20.145"
										height="17.076"
										viewBox="0 0 20.145 17.076"
									>
										<g
											id="case-svgrepo-com"
											transform="translate(0.25 -39.75)"
										>
											<path
												id="Path_34276"
												data-name="Path 34276"
												d="M19.031,43.07h-4.3a.614.614,0,0,0-.614-.614v-.614A1.844,1.844,0,0,0,12.278,40H7.367a1.844,1.844,0,0,0-1.842,1.842v.614a.614.614,0,0,0-.614.614H.614A.614.614,0,0,0,0,43.683V55.348a.614.614,0,0,0,.614.614H2.456a.614.614,0,0,0,.614.614H4.3a.614.614,0,0,0,.614-.614h9.823a.614.614,0,0,0,.614.614h1.228a.614.614,0,0,0,.614-.614h1.842a.614.614,0,0,0,.614-.614V43.683A.614.614,0,0,0,19.031,43.07ZM6.753,41.842a.615.615,0,0,1,.614-.614h4.911a.615.615,0,0,1,.614.614v.614a.614.614,0,0,0-.614.614H7.367a.614.614,0,0,0-.614-.614ZM18.417,54.734H1.228V44.3h17.19Z"
												fill="#fff"
												stroke="#fff"
												stroke-width="0.5"
											/>
											<path
												id="Path_34277"
												data-name="Path 34277"
												d="M112.614,206.753a.614.614,0,0,0,.614-.614v-5.525a.614.614,0,1,0-1.228,0v5.525A.614.614,0,0,0,112.614,206.753Z"
												transform="translate(-107.703 -153.861)"
												fill="#c9acb1"
											/>
											<path
												id="Path_34278"
												data-name="Path 34278"
												d="M368.614,206.753a.614.614,0,0,0,.614-.614v-5.525a.614.614,0,1,0-1.228,0v5.525A.614.614,0,0,0,368.614,206.753Z"
												transform="translate(-353.88 -153.861)"
												fill="#c9acb1"
											/>
										</g>
									</svg>

									<span class="tw-font-medium">{{
										$t('signUp.subActivity')
									}}</span>
								</div>
								<v-select
									hide-details="auto"
									:rules="[rules.required($t('signUp.subActivity'))]"
									class="focus-bg-none"
									:placeholder="$t('signUp.subActivityPlaceholder')"
									:items="subActivity"
									item-text="label"
									item-value="value"
									v-model="registration.company.subActivity"
									:label="$t('signUp.subActivity')"
									solo
								></v-select>
							</div>
						</v-col>
						<v-col class="">
							<div class="mt-4 text-center">
								<v-btn
									dark
									height="60"
									block
									type="submit"
									color="#A53E50"
									:loading="loading"
									class="shadow tw-flex tw-justify-between tw-px-4 xl:tw-px-8"
								>
									<v-icon>mdi-arrow-right</v-icon>
									<span
										class="tw-text-18 tw-font-pop tw-normal-case tw-text-base xl:tw-text-lg tw-font-normal tw-text-center tw-flex-1"
										>{{ $t('signUp.proceed') }}</span
									>
								</v-btn>
							</div>
						</v-col>
					</v-row>
				</v-form>
			</v-stepper-content>

			<v-stepper-content step="2">
				<v-form ref="addressForm" @submit.prevent="validateAddress">
					<v-row>
						<v-col cols="12" md="6">
							<div>
								<div
									class="tw-flex tw-items-center tw-space-s-3.5 tw-text-white tw-mb-4"
								>
									<icon-marker></icon-marker>
									<span class="tw-font-medium">{{
										$t('signUp.buildingAddress')
									}}</span>
								</div>
								<v-text-field
									solo
									:rules="[
										rules.required($t('signUp.buildingAddress')),
									]"
									v-model="registration.address.buildingNumber"
									class="focus-bg-none"
									:placeholder="
										$t('signUp.buildingAddressPlaceholder')
									"
									hide-details="auto"
								/>
							</div>
						</v-col>

						<v-col cols="12" md="6">
							<div>
								<div
									class="tw-flex tw-items-center tw-space-s-3.5 tw-text-white tw-mb-4"
								>
									<icon-marker></icon-marker>
									<span class="tw-font-medium">{{
										$t('signUp.streetName')
									}}</span>
								</div>
								<v-text-field
									solo
									:rules="[rules.required($t('signUp.streetName'))]"
									v-model="registration.address.streetName"
									class="focus-bg-none"
									:placeholder="$t('signUp.streetNamePlaceholder')"
									hide-details="auto"
								/>
							</div>
						</v-col>
						<v-col cols="12" md="6">
							<div>
								<div
									class="tw-flex tw-items-center tw-space-s-3.5 tw-text-white tw-mb-4"
								>
									<icon-marker></icon-marker>
									<span class="tw-font-medium">
										{{ $t('signUp.neighbourhoodName') }}</span
									>
								</div>
								<v-text-field
									solo
									:rules="[
										rules.required($t('signUp.neighbourhoodName')),
									]"
									v-model="registration.address.neighborhoodName"
									class="focus-bg-none"
									:placeholder="
										$t('signUp.neighbourhoodNamePlaceholder')
									"
									hide-details="auto"
								/>
							</div>
						</v-col>

						<v-col cols="12" md="6">
							<div>
								<div
									class="tw-flex tw-items-center tw-space-s-3.5 tw-text-white tw-mb-4"
								>
									<icon-marker></icon-marker>
									<span class="tw-font-medium">{{
										$t('signUp.city')
									}}</span>
								</div>
								<v-text-field
									solo
									v-model="registration.address.city"
									class="focus-bg-none"
									:placeholder="$t('signUp.cityPlaceholder')"
									hide-details="auto"
								/>
							</div>
						</v-col>
						<v-col cols="12" md="6">
							<div>
								<div
									class="tw-flex tw-items-center tw-space-s-3.5 tw-text-white tw-mb-4"
								>
									<icon-map-marker></icon-map-marker>
									<span class="tw-font-medium">{{
										$t('signUp.postalCode')
									}}</span>
								</div>
								<v-text-field
									solo
									:rules="[rules.required($t('signUp.postalCode'))]"
									type="number"
									v-model="registration.address.postalCode"
									class="focus-bg-none"
									:placeholder="$t('signUp.postalCodePlaceholder')"
									hide-details="auto"
								/>
							</div>
						</v-col>
						<v-col cols="12" md="6">
							<div>
								<div
									class="tw-flex tw-items-center tw-space-s-3.5 tw-text-white tw-mb-4"
								>
									<icon-building></icon-building>
									<span class="tw-font-medium">{{
										$t('signUp.unitNumber')
									}}</span>
								</div>
								<v-text-field
									solo
									:rules="[rules.required($t('signUp.unitNumber'))]"
									type="number"
									v-model="registration.address.unitNumber"
									class="focus-bg-none"
									:placeholder="$t('signUp.unitNumberPlaceholder')"
									hide-details="auto"
								/>
							</div>
						</v-col>
						<v-col cols="12" md="6">
							<div>
								<div
									class="tw-flex tw-items-center tw-space-s-3.5 tw-text-white tw-mb-4"
								>
									<icon-building></icon-building>
									<span class="tw-font-medium">{{
										$t('signUp.extraNumber')
									}}</span>
								</div>

								<v-text-field
									solo
									:rules="[rules.required($t('signUp.extraNumber'))]"
									type="number"
									class="focus-bg-none"
									:placeholder="$t('signUp.extraNumberPlaceholder')"
									v-model="registration.address.extraNumber"
									:label="$t('signUp.extraNumber')"
									hide-details="auto"
								/>
							</div>
						</v-col>
						<v-col cols="12">
							<div
								class="tw-border-dashed tw-border-2 tw-border-DF tw-border-opacity-70 tw-rounded-lg tw-ps-6 tw-pe-2 tw-py-2 tw-flex tw-items-center tw-justify-between"
							>
								<div class="tw-flex tw-flex-col tw-space-y-1.5">
									<span class="tw-font-medium tw-text-white">{{
										$t('signUp.addressAttachment')
									}}</span>
									<span class="tw-font-medium tw-text-CA tw-text-xs">{{
										$t('signUp.attachmentSizeHint')
									}}</span>
								</div>
								<v-chip
									class="tw-px-4"
									v-if="this.registration.address.attachment"
									dark
									color="primary"
								>
									<span class="tw-w-20 tw-truncate">{{
										this.registration.address.attachment.name
									}}</span>
									<v-icon class="tw-me-2">mdi-file</v-icon>
								</v-chip>
								<v-btn
									@click="onAdressAttachment"
									width="152"
									height="52"
									class="tw-rounded-md"
									depressed
									dark
									color="#F2CA51"
								>
									<icon-login class="tw-me-3 xl:tw-me-6" />
									<span
										class="xl:tw-text-lg tw-text-base tw-normal-case tw-font-normal"
										>{{ $t('actions.upload') }}</span
									>
								</v-btn>
							</div>
							<span
								v-if="showAddressAttachError"
								class="red--text tw-ms-2 tw-text-xs"
								>{{ $t('proof-national-address-required') }}</span
							>
						</v-col>
						<v-col>
							<div class="mt-4 text-center">
								<v-btn
									dark
									height="60"
									block
									type="submit"
									color="#A53E50"
									:loading="loading"
									class="shadow tw-flex tw-justify-between tw-px-4 xl:tw-px-8"
								>
									<v-icon>mdi-arrow-right</v-icon>
									<span
										class="tw-text-18 tw-font-pop tw-normal-case tw-text-base xl:tw-text-lg tw-font-normal tw-text-center tw-flex-1"
										>{{ $t('signUp.proceed') }}</span
									>
								</v-btn>
							</div>
						</v-col>
					</v-row>
				</v-form>
			</v-stepper-content>

			<v-stepper-content step="3">
				<v-form ref="bankForm" @submit.prevent="validateBankInfo">
					<v-row>
						<v-col cols="12" md="6">
							<div>
								<div
									class="tw-flex tw-items-center tw-space-s-3.5 tw-text-white tw-mb-4"
								>
									<icon-user></icon-user>
									<span class="tw-font-medium">{{
										$t('signUp.accountName')
									}}</span>
								</div>
								<v-text-field
									solo
									:rules="[rules.required($t('signUp.accountName'))]"
									v-model="registration.bank.accountName"
									class="focus-bg-none"
									:placeholder="$t('signUp.accountNamePlaceholder')"
									hide-details="auto"
								/>
							</div>
						</v-col>

						<v-col cols="12" md="6">
							<div>
								<div
									class="tw-flex tw-items-center tw-space-s-3.5 tw-text-white tw-mb-4"
								>
									<icon-bank-building></icon-bank-building>
									<span class="tw-font-medium">{{
										$t('signUp.bankName')
									}}</span>
								</div>

								<v-autocomplete
									hide-details="auto"
									:rules="[rules.required($t('signUp.bankName'))]"
									class="focus-bg-none"
									:items="$banks"
									item-text="bankName"
									item-value="id"
									v-model="registration.bank.bankId"
									:placeholder="$t('signUp.bankNamePlaceholder')"
									solo
								></v-autocomplete>
							</div>
						</v-col>
						<v-col cols="12" md="6">
							<div>
								<div
									class="tw-flex tw-items-center tw-space-s-3.5 tw-text-white tw-mb-4"
								>
									<icon-credit-card></icon-credit-card>
									<span class="tw-font-medium">{{
										$t('signUp.accountNumber')
									}}</span>
								</div>
								<v-text-field
									solo
									:rules="[rules.required($t('signUp.accountNumber'))]"
									v-model="registration.bank.accountNumber"
									class="focus-bg-none"
									:placeholder="$t('signUp.accountNumberPlaceholder')"
									hide-details="auto"
								/>
							</div>
						</v-col>

						<v-col cols="12" md="6">
							<div
								class="tw-flex tw-items-center tw-space-s-3.5 tw-text-white tw-mb-4"
							>
								<v-icon dark>mdi-check</v-icon>
								<span class="tw-font-medium">{{
									$t('signUp.isOwner')
								}}</span>
							</div>
							<v-btn-toggle
								mandatory
								class="tw-w-1/2"
								v-model="registration.buyer.isOwner"
							>
								<v-btn
									:color="
										+registration.buyer.isOwner
											? '#F2CA51'
											: '#D0D0D0'
									"
									block
									value="1"
									class=""
								>
									<span
										class="tw-text-base tw-text-white tw-normal-case"
										>{{ $t('yes') }}</span
									>
								</v-btn>
								<v-btn
									:color="
										!+registration.buyer.isOwner
											? '#F2CA51'
											: '#D0D0D0'
									"
									block
									value="0"
									class=""
								>
									<span
										class="tw-text-base tw-text-white tw-normal-case"
										>{{ $t('no') }}</span
									>
								</v-btn>
							</v-btn-toggle>
						</v-col>

						<v-col cols="12">
							<div
								class="tw-border-dashed tw-border-2 tw-border-DF tw-border-opacity-70 tw-rounded-lg tw-ps-6 tw-pe-2 tw-py-2 tw-flex tw-items-center tw-justify-between"
							>
								<div class="tw-flex tw-flex-col tw-space-y-1.5">
									<span class="tw-font-medium tw-text-white">{{
										$t('signUp.bankAttachment')
									}}</span>
									<span class="tw-font-medium tw-text-CA tw-text-xs">{{
										$t('signUp.attachmentSizeHint')
									}}</span>
								</div>
								<v-chip
									class="tw-px-4"
									v-if="this.registration.bank.accountAttachment"
									dark
									color="primary"
								>
									<span class="tw-w-20 tw-truncate">{{
										this.registration.bank.accountAttachment.name
									}}</span>
									<v-icon class="tw-me-2">mdi-file</v-icon>
								</v-chip>
								<v-btn
									@click="onBankAttachment"
									width="152"
									height="52"
									class="tw-rounded-md"
									depressed
									dark
									color="#F2CA51"
								>
									<icon-login class="tw-me-3 xl:tw-me-6"></icon-login>
									<span
										class="tw-text-lg xl:tw-text-lg tw-normal-case tw-font-normal"
										>{{ $t('actions.upload') }}</span
									>
								</v-btn>
							</div>
							<span
								v-if="showBankAttachError"
								class="red--text tw-ms-2 tw-text-xs"
								>{{ $t('bank-attach-required') }}</span
							>
						</v-col>
						<v-col cols="12">
							<div>
								<div
									class="tw-flex tw-items-center tw-space-s-3.5 tw-text-white tw-mb-4"
								>
									<icon-building></icon-building>
									<span class="tw-font-medium">{{
										$t('signUp.companyRepresentativeName')
									}}</span>
								</div>
								<v-text-field
									solo
									:rules="[
										rules.required($t('branch.representativeName')),
									]"
									v-model="registration.buyer.representativeName"
									class="focus-bg-none"
									:placeholder="$t('signUp.companyRepresentativeName')"
									hide-details="auto"
								/>
							</div>
						</v-col>
						<v-col cols="12">
							<div
								class="tw-border-dashed tw-border-2 tw-border-DF tw-border-opacity-70 tw-rounded-lg tw-ps-6 tw-pe-2 tw-py-2 tw-flex tw-items-center tw-justify-between"
							>
								<div class="tw-flex tw-flex-col tw-space-y-1.5">
									<span class="tw-font-medium tw-text-white">{{
										$t('signUp.representativeAuthorizationLetter')
									}}</span>
									<span class="tw-font-medium tw-text-CA tw-text-xs">{{
										$t('signUp.attachmentSizeHint')
									}}</span>
								</div>
								<v-chip
									class="tw-px-4"
									v-if="
										this.registration.buyer.representativeAttachment
									"
									dark
									color="primary"
								>
									<span class="tw-w-20 tw-truncate">{{
										this.registration.buyer.representativeAttachment
											.name
									}}</span>
									<v-icon class="tw-me-2">mdi-file</v-icon>
								</v-chip>
								<v-btn
									@click="onRepresentativeAttachment"
									width="152"
									height="52"
									class="tw-rounded-md"
									depressed
									dark
									color="#F2CA51"
								>
									<icon-login class="tw-me-3 xl:tw-me-6"></icon-login>
									<span
										class="tw-text-base xl:tw-text-lg tw-normal-case tw-font-normal"
										>{{ $t('actions.upload') }}</span
									>
								</v-btn>
							</div>
							<span
								v-if="showRepresentativeAttachment"
								class="red--text tw-ms-2 tw-text-xs"
								>{{ $t('repr-auth-required') }}</span
							>
						</v-col>
						<v-col class="">
							<div class="mt-4 text-center">
								<v-btn
									dark
									height="60"
									block
									type="submit"
									color="#A53E50"
									:loading="loading"
									class="shadow tw-flex tw-justify-between tw-px-4 xl:tw-px-8"
								>
									<v-icon>mdi-check</v-icon>
									<span
										class="tw-text-18 tw-font-pop tw-normal-case tw-text-base xl:tw-text-lg tw-font-normal tw-text-center tw-flex-1"
										>{{ $t('signUp.completeRegistration') }}</span
									>
								</v-btn>
							</div>
						</v-col>
					</v-row>
				</v-form>
			</v-stepper-content>
		</v-stepper-items>
	</v-stepper>
</template>

<script>
import { toFormData } from 'vuelpers'
import { mapActions, mapGetters } from 'vuex'
import { createFormMixin } from '@/mixins/form-mixin'

import UseFilePicker from '@/mixins/UseFilePicker'

export default {
	name: 'SignUpForm',
	mixins: [
		UseFilePicker,
		createFormMixin({
			rules: ['required', 'email', 'password', 'phoneCountry'],
		}),
	],
	props: {
		loading: Boolean,
		role: {
			type: String,
			default: 'buyer',
		},
		onSubmit: {
			type: Function,
			default: () => {},
		},
	},
	data() {
		return {
			currentStep: 1,
			isValid: false,
			registration: {},
			success: null,
			menu: false,
			showCompanyAttachError: false,
			showAddressAttachError: false,
			showBankAttachError: false,
			showRepresentativeAttachment: false,
			unsubscribeDocumentPaste: () => {},
			errors: {},
		}
	},
	created() {
		this.registration = this.initialRegistration()

		this.getBank()
		this.getUnAuthorisedCategories({ type: this.role, per_page: 0 })
	},
	computed: {
		...mapGetters('bank', ['$banks']),
		...mapGetters('category', [
			'$categories',
			'$unAuthBuyerCategories',
			'$unAuthSupplierCategories',
		]),

		categories() {
			if (this.role === 'buyer') return this.$unAuthBuyerCategories
			return this.$unAuthSupplierCategories
		},
		activity() {
			return this.categories.data.map(d => ({
				value: d.id,
				label: d.name,
			}))
		},
		subActivity() {
			let category = this.categories.data.find(
				sub => sub.id === this.registration.company.mainActivity
			)
			if (!category) return []
			return category.childCategories.map(a => ({
				value: a.id,
				label: a.name,
			}))
		},
	},
	methods: {
		...mapActions('category', ['getUnAuthorisedCategories']),
		...mapActions('bank', ['getBank']),
		onInputCompanyPhone() {
			// this.registration.company.phone = new AsYouType('SA').input(phone)
			this.errors.companyPhone = null
			// try {
			// 	// const parsed = parsePhoneNumber(phone,'SA')
			// 	// console.log({ parsed })

			// 	// console.log(new AsYouType().input(phone))
			// } catch (_) {
			// 	//
			// }
		},
		initialRegistration() {
			return {
				buyer: {
					representativeName: '',
					CanBuyerSee: 0,
					isOwner: false,
					representativeAttachment: null,
				},
				company: {
					name: '',
					registrationNumber: '',
					recordExpDate: '',
					storeName: '',
					email: '',
					phone: '',
					mainActivity: null,
					subActivity: null,
					registrationAttachment: null,
				},
				bank: {
					accountName: '',
					accountNumber: '',
					bankId: '',
					accountAttachment: null,
				},
				address: {
					city: '',
					postalCode: '',
					extraNumber: '',
					streetName: '',
					neighborhoodName: '',
					unitNumber: '',
					buildingNumber: '',
					attachment: null,
				},
			}
		},
		handleSendOtp(number) {
			this.$emit('handleSendOtp', number)
		},
		onCompanyAttachment() {
			this.openFilePicker(
				{ multiple: false, accept: '.png,.pdf,.jpg,.jpeg' },
				image => {
					this.registration.company.registrationAttachment = image.file
					this.showCompanyAttachError = false
				}
			)
		},
		onAdressAttachment() {
			this.openFilePicker(
				{ multiple: false, accept: '.png,.pdf,.jpg,.jpeg' },
				image => {
					this.registration.address.attachment = image.file
					this.showAddressAttachError = false
				}
			)
		},
		onBankAttachment() {
			this.openFilePicker(
				{ multiple: false, accept: '.png,.pdf,.jpg,.jpeg' },
				image => {
					this.registration.bank.accountAttachment = image.file
					this.showBankAttachError = false
				}
			)
		},
		onRepresentativeAttachment() {
			this.openFilePicker(
				{ multiple: false, accept: '.png,.pdf,.jpg,.jpeg' },
				image => {
					this.registration.buyer.representativeAttachment = image.file
					this.showRepresentativeAttachment = false
				}
			)
		},
		validateCompany() {
			this.showCompanyAttachError = !this.registration.company
				.registrationAttachment
			if (
				!this.$refs.companyForm.validate() ||
				this.showCompanyAttachError
			) {
				this.$utils.scrollIntoError(this)
				return
			}

			this.currentStep = 2
		},
		validateAddress() {
			this.showAddressAttachError = !this.registration.address.attachment
			if (
				!this.$refs.addressForm.validate() ||
				this.showAddressAttachError
			) {
				this.$utils.scrollIntoError(this)
				return
			}

			this.currentStep = 3
		},
		async validateBankInfo() {
			this.showBankAttachError = !this.registration.bank.accountAttachment
			this.showRepresentativeAttachment = !this.registration.buyer
				.representativeAttachment

			if (
				!this.$refs.bankForm.validate() ||
				this.showBankAttachError ||
				this.showRepresentativeAttachment
			) {
				this.$utils.scrollIntoError(this)
				return
			}

			const [err] = await this.onSubmit(
				toFormData(
					{
						company: {
							...this.registration.company,
							recordExpDate: this.$m(
								this.registration.company.recordExpDate
							).format(this.$t('yyyy-mm-dd-hh-mm-ss')),
						},
						address: {
							...this.registration.address,
						},
						bank: {
							...this.registration.bank,
							accountNumber: 'SA' + this.registration.bank.accountNumber,
						},
						[this.role]: {
							...this.registration.buyer,
							subCategoryId: this.registration.company.subActivity,
							mainCategoryId: this.registration.company.mainActivity,
						},
					},
					{ convertCase: 'snake_case' }
				)
			)

			this.errors = err?.errors || {}

			const errorKeys = Object.keys(this.errors)
			if (!errorKeys.length) return

			const steps = { 1: ['phone', 'email'] }
			const [step = 1] =
				Object.entries(steps).find(entry => {
					return entry[1].some(keyword => {
						return errorKeys.some(errorKey => {
							return errorKey.includes(keyword)
						})
					})
				}) || []

			this.currentStep = step
			this.$utils.scrollIntoError(this)
		},
	},
	mounted() {
		this.unsubscribeDocumentPaste = this.$utils.useDocumentPaste(
			[
				'buyer.representativeAttachment',
				'company.registrationAttachment',
				'bank.accountAttachment',
				'address.attachment',
			],
			this.registration
		)
	},
	beforeDestroy() {
		this.unsubscribeDocumentPaste()
	},
}
</script>

<style lang="scss">
.base-stepper {
	.v-stepper__wrapper {
		overflow: visible !important;
	}
}
.v-stepper__header {
	box-shadow: none !important;
}
</style>
